// Generated by Framer (68f6254)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["hxH6Vd546", "ixsceJqZS"];

const serializationHash = "framer-vdlVt"

const variantClassNames = {hxH6Vd546: "framer-v-1332kj7", ixsceJqZS: "framer-v-h0qcjs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "ixsceJqZS", Menu: "hxH6Vd546"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "hxH6Vd546", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hxH6Vd546", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapc2fruv = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {const res = await XKaY3MrGu(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1332kj7", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"hxH6Vd546"} onTap={onTapc2fruv} ref={ref ?? ref1} style={{backdropFilter: "blur(5px)", backgroundColor: "var(--token-547b5c15-77fe-4f79-a0d6-e024d7d798f1, rgba(255, 255, 255, 0.5))", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, WebkitBackdropFilter: "blur(5px)", ...style}} variants={{ixsceJqZS: {backgroundColor: "rgba(255, 255, 255, 0.9)"}}} {...addPropertyOverrides({ixsceJqZS: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-15nwjay"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"pKsYwSZzy"} style={{backgroundColor: "var(--token-00af65c3-92fd-4f69-9228-cfe71d95f29a, rgb(0, 0, 0))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{ixsceJqZS: {rotate: -45}}}/><motion.div className={"framer-1wmlss9"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"kQ5f1eczH"} style={{backgroundColor: "var(--token-00af65c3-92fd-4f69-9228-cfe71d95f29a, rgb(0, 0, 0))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{ixsceJqZS: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vdlVt.framer-znuqfy, .framer-vdlVt .framer-znuqfy { display: block; }", ".framer-vdlVt.framer-1332kj7 { cursor: pointer; height: 44px; overflow: hidden; position: relative; width: 44px; will-change: var(--framer-will-change-override, transform); }", ".framer-vdlVt .framer-15nwjay { flex: none; height: 1px; left: calc(50.00000000000002% - 16px / 2); overflow: hidden; position: absolute; top: calc(59.090909090909115% - 1px / 2); width: 16px; will-change: var(--framer-will-change-override, transform); }", ".framer-vdlVt .framer-1wmlss9 { flex: none; height: 1px; left: calc(50.00000000000002% - 16px / 2); overflow: hidden; position: absolute; top: calc(38.636363636363654% - 1px / 2); width: 16px; will-change: var(--framer-will-change-override, transform); }", ".framer-vdlVt.framer-v-h0qcjs.framer-1332kj7 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 44px); }", ".framer-vdlVt.framer-v-h0qcjs .framer-15nwjay, .framer-vdlVt.framer-v-h0qcjs .framer-1wmlss9 { top: calc(50.00000000000002% - 1px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ixsceJqZS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqgBY9FRv5: React.ComponentType<Props> = withCSS(Component, css, "framer-vdlVt") as typeof Component;
export default FramerqgBY9FRv5;

FramerqgBY9FRv5.displayName = "Icon";

FramerqgBY9FRv5.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerqgBY9FRv5, {variant: {options: ["hxH6Vd546", "ixsceJqZS"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerqgBY9FRv5, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})